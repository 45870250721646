import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create("RecordChannel", {
      received: this.handleMessage.bind(this),
      connected: () => {},
      disconnected: () => {},
      rejected: () => {},
    });
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  destroy(event) {
    event.preventDefault();
    if (confirm("Are you sure?")) {
      const recordId = event.currentTarget.dataset.recordId;
      const className = event.currentTarget.dataset.className;

      this.subscription.perform("destroy", {
        record_id: recordId,
        class_name: className,
      });
    }
  }

  toggleOrder(event) {
    event.preventDefault();
    const orderId = event.currentTarget.dataset.id;

    this.subscription.perform("toggle_order", {
      id: orderId,
    });
  }

  updateRecord(event) {
    event.preventDefault();
    const recordId = event.currentTarget.dataset.recordId;
    const className = event.currentTarget.dataset.className;
    const field = event.currentTarget.dataset.field;
    const partial = event.currentTarget.dataset.partial;

    this.subscription.perform("update_record", {
      record_id: recordId,
      class_name: className,
      field: field,
      partial: partial,
    });
  }

  notificationReadAt(event) {
    event.preventDefault();
    const userId = event.currentTarget.dataset.userId;
    const className = event.currentTarget.dataset.className;

    this.subscription.perform("notification_read_at", {
      user_id: userId,
      class_name: className,
    });
  }

  handleMessage(data) {
    if (data.action === "destroy") {
      let container = null;

      if (data.class_name === "ReservationType") {
        container = document.getElementById(
          `reservation-type-container-${data.record_id}`
        );
      } else if (data.class_name === "RestaurantTicketType") {
        container = document.getElementById(
          `ticket-type-container-${data.record_id}`
        );
      } else if (data.class_name === "PaidTable") {
        container = document.getElementById(
          `paid-table-container-${data.record_id}`
        );
      }

      if (container) {
        container.remove();
      }
    } else if (data.action === "toggle_order") {
      const row = document.querySelector(`#row-${data.order_id}`);
      if (row) {
        row.outerHTML = data.html;
      }
    } else if (data.action === "update_record") {
      const row = document.querySelector(`.update-html-${data.record_id}`);
      if (row) {
        row.outerHTML = data.html;
      }
    }
  }
}
