import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modalContent', 'refresh']
  static values = {
    url: String
  }

  updateHtml(event) {
    event.stopPropagation()
    const [_data, _status, xhr] = event.detail
    this.modalContentTarget.innerHTML = xhr.response
  }

  groupFormRefresh() {
    $.ajax({
      url: this.urlValue,
      type: 'get',
      success: (data) => {
        this.refreshTarget.innerHTML = data
      }
    })
  }
}
