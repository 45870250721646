// controllers/sortable_controller.js
import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['item']
  static values = {
    handle: String,
    animation: { type: Number, default: 150 },
    ghostClass: { type: String, default: 'sortable-ghost' },
    dragClass: { type: String, default: 'sortable-drag' },
    chosenClass: { type: String, default: 'sortable-chosen' },
    selectedClass: { type: String, default: 'sortable-selected' },
    resource: String,
    resourceName: String,
    resourceId: String,
    positionName: { type: String, default: 'position' },
    paramName: { type: String, default: 'position' },
    csrf: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: this.animationValue,
      handle: this.handleValue || undefined,
      ghostClass: this.ghostClassValue,
      dragClass: this.dragClassValue,
      chosenClass: this.chosenClassValue,
      selectedClass: this.selectedClassValue,
      onEnd: this.onEnd.bind(this)
    })

    console.log('Sortable controller connected')
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
    }
  }

  onEnd(event) {
    // Get the item that was moved
    const item = event.item
    // Get the update URL from the data attribute on the item
    const url = item.dataset.sortableUpdateUrl

    if (!url) {
      console.error('No update URL found on the item')
      return
    }

    const resourceName = this.resourceNameValue || 'resource'
    const paramName = this.paramNameValue || 'position'
    const csrfToken =
      this.csrfValue ||
      document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

    // Get all items to determine the new positions for each one
    const items = Array.from(this.element.children)

    // Build the request body
    const body = {}
    body[resourceName] = {}
    body[resourceName][paramName] = event.newIndex + 1

    console.log(`Sending update to ${url} with data:`, body)

    // Use fetch to update the position
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        Accept: 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'same-origin' // Include cookies in the request
    })
      .then((response) => {
        if (!response.ok) {
          console.error('Server responded with error:', response.status)
          throw new Error(`Server responded with status: ${response.status}`)
        }

        // Check if the response has content
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
          return response.json().catch((e) => {
            console.warn('Empty or invalid JSON response:', e)
            return {}
          })
        } else {
          // If it's not JSON, just consider it a success with no data
          console.log(
            'Non-JSON response received, considering request successful'
          )
          return {}
        }
      })
      .then((data) => {
        console.log('Position updated successfully')
      })
      .catch((error) => {
        console.error('Error updating position:', error)
      })
  }
}
