import { Controller } from '@hotwired/stimulus'

import bootstrap from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ['contentModal', 'menuAccordion', 'sortModal']

  connect() {
    this.bindModalCloseEvents()
  }

  bindModalCloseEvents() {
    this.sortModalTargets.forEach((modalElement) => {
      modalElement?.addEventListener(
        'hidden.bs.modal',
        this.refreshMenuAccordion.bind(this)
      )
    })
  }

  refreshMenuAccordion() {
    const menuAccordion = this.menuAccordionTarget
    const openAccordionMenu = menuAccordion.querySelector('.collapse.show')
    const openAccordionMenuId = openAccordionMenu ? openAccordionMenu.id : null
    const scheduleName = menuAccordion.getAttribute('data-menu-schedule-name')

    this.updateMenuAccordion(scheduleName, openAccordionMenuId)
  }

  updateModal(event) {
    const [_data, _status, xhr] = event.detail
    this.contentModalTarget.innerHTML = xhr.response

    const modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById('sortMeals')
    )
    modal.show()
  }

  updateMenuAccordion(scheduleName, openAccordionMenuId) {
    const data = {
      schedule_name: scheduleName,
      open_accordion_menu_id: openAccordionMenuId
    }

    $.ajax({
      url: '/restaurant/settings/online_orders/menus/update_menu_accordion',
      type: 'get',
      data: data,
      success: (data) => {
        this.menuAccordionTarget.innerHTML = data
      },
      error: (error) => {
        console.error('Error updating menu accordion:', error)
      }
    })
  }
}
