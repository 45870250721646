import consumer from "./consumer";

const JobProcessingChannel = {
  subscribe(fileId, callbacks) {
    return consumer.subscriptions.create(
      { channel: "JobProcessingChannel", file_id: fileId },
      {
        connected() {
          callbacks.connected?.();
        },
        disconnected() {
          callbacks.disconnected?.();
        },
        received(data) {
          callbacks.received?.(data);
        },
      }
    );
  },
};

export default JobProcessingChannel;
