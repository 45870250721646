/* HTML Example:
<div data-controller="copy-to-clipboard">
  <i class="fa-regular fa-copy cursor-pointer"
              title="Copy"
              data-bs-toggle="tooltip"
              data-copy-to-clipboard-target="icon"
              data-copy-to-clipboard-value="Value to copy"
              data-action="click->copy-to-clipboard#copy"></i>
  <i class="fas fa-check-circle text-success d-none" data-copy-to-clipboard-target="checkmark"></i>
</div> 
*/

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'checkmark']

  copy(event) {
    event.preventDefault()

    const phoneNumber = this.iconTarget.getAttribute(
      'data-copy-to-clipboard-value'
    )

    navigator.clipboard.writeText(phoneNumber).then(() => {
      this.iconTarget.classList.add('d-none')
      this.checkmarkTarget.classList.remove('d-none')

      setTimeout(() => {
        this.iconTarget.classList.remove('d-none')
        this.checkmarkTarget.classList.add('d-none')
      }, 2000)
    })
  }
}
