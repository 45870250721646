/*
Changing Date: 
 - Sheets Enabled: Reset Tables & Timeslots
 - Else: Reload Timeslots
Changing Party Size:
 - Reload Timeslots
Changing Table:
 - Reload Timeslots
*/
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['date', 'table', 'time', 'party', 'spinner']

  connect() {
    this.dateTarget.addEventListener('change', () => this.handleDateChange())
    this.partyTarget.addEventListener('change', () => this.handlePartyChange())

    if (this.hasTableTarget) {
      this.tableTarget.addEventListener('change', () =>
        this.handleTableChange()
      )
    }

    this.element.addEventListener('ajax:beforeSend', () => this.showSpinner())
    this.element.addEventListener('ajax:success', () => this.hideSpinner())
    this.element.addEventListener('ajax:error', () => this.hideSpinner())
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('d-none')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('d-none')
  }

  handleDateChange() {
    this.resetTimeslots()
    this.resetTables()
    this.reloadTimeslots()
  }

  handlePartyChange() {
    this.resetTimeslots()
    this.reloadTimeslots()
  }

  handleTableChange() {
    this.resetTimeslots()
    this.reloadTimeslots()
  }

  reloadTimeslots() {
    let reservationOn = this.dateTarget.value
    let partySize = this.partyTarget.value
    let table = null
    let originalTimeslot =
      document.querySelector('#reservation_original_timeslot')?.value || null

    if (this.hasTableTarget) {
      table = this.tableTarget.value
    }

    fetch('/restaurant/direct_reservations/find_available_timeslots', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
      },
      body: JSON.stringify({
        reservation_on: reservationOn,
        table_id: table,
        party_size: partySize,
        timeslot: originalTimeslot
      })
    })
      .then((response) => response.json())
      .then((data) => {
        this.timeTarget.innerHTML = ''
        const originalTimeslotElement = document.querySelector(
          '#reservation_original_timeslot'
        )
        const originalTimeslot = originalTimeslotElement
          ? originalTimeslotElement.value
          : null

        if (data.timeslots.length > 0) {
          data.timeslots.forEach((timeslot) => {
            let option = new Option(
              timeslot[0],
              timeslot[1],
              false,
              timeslot[1] === parseInt(originalTimeslot)
            )
            this.timeTarget.appendChild(option)
          })
        } else {
          let noTimeslotsOption = new Option('No available timeslots', null)
          this.timeTarget.appendChild(noTimeslotsOption)
        }
      })
      .catch((error) => console.log(error))
  }

  resetTables() {
    if (!this.hasTableTarget) {
      return
    }

    if (document.querySelector('#reservation_original_table')) {
      return
    }

    let existingOption = Array.from(this.tableTarget.options).find(
      (option) => option.text === 'Select Table (optional)'
    )

    if (!existingOption) {
      let reselectOption = new Option('Select Table (optional)', '')
      this.tableTarget.insertBefore(reselectOption, this.tableTarget.firstChild)
    }

    this.tableTarget.value = ''
  }

  resetTimeslots() {
    this.timeTarget.innerHTML = ''
    let loadingOption = new Option('Loading Timeslots...', null)
    this.timeTarget.appendChild(loadingOption)
  }

  closeModal() {
    var modalElement = document.querySelector('#ajaxModal.show')

    if (modalElement) {
      var modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement)
      modalInstance.hide()
    }
  }
}
