import { Controller } from '@hotwired/stimulus'
import bootstrap from 'bootstrap/dist/js/bootstrap'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'

export default class extends Controller {
  static targets = ['modalForm']

  connect() {
    if (this.modalFormTarget.innerHTML === '') {
      return
    }

    this.#instantiateFlatpickr()
    this.#listenToDateChange()
    this.#listenToPartyChange()
  }

  getPartialAndUpdateModal(event) {
    const [_data, _status, xhr] = event.detail
    this.modalFormTarget.innerHTML = xhr.response

    this.#instantiateFlatpickr()
    this.#updateAndShowModal(document.getElementById('ajaxModal'))
    this.#listenToDateChange()
    this.#listenToPartyChange()
  }

  // Private Methods

  #instantiateFlatpickr() {
    flatpickr('.flatpickr', {
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      minDate: 'today'
    })
  }

  #updateAndShowModal(modalElement) {
    var ajaxModal = bootstrap.Modal.getOrCreateInstance(modalElement)
    ajaxModal.show()
  }

  #listenToDateChange() {
    $('#date').change(function (e) {
      const reservation_id = $('.reservation_edit').attr('data-reservation-id')
      var reservation_on = $('#date').val()
      var party_size = $('#party_size').val()
      var timeslot = $('#time').val()

      $.ajax({
        dataType: 'json',
        type: 'post',
        url: '/patron/reservations/check_inventory',
        data: {
          reservation_id: reservation_id,
          reservation_on: reservation_on,
          party_size: party_size,
          timeslot: timeslot
        },
        success: function (data) {
          console.log(data)
          console.log(data['timeslots'])
          $('#time').find('option').remove()
          var timeslots = data.timeslots
          if (timeslots.length == 0) {
            $('.timeslots').hide()
            $('.no-timeslots').show()
            $('.no-timeslots').replaceWith(
              '<p class="col-12 col-lg-6 no-timeslots">' + data.message + '</p>'
            )
          } else {
            $('#time').append(new Option('Please select a time', null))
            for (var i = 0; i < timeslots.length; i++) {
              $('.no-timeslots').hide()
              $('.timeslots').show()
              $('#time').append(new Option(timeslots[i][0], timeslots[i][1]))
            }
          }
        },
        error: function (data) {
          console.log(data)
        }
      })
    })
  }

  #listenToPartyChange() {
    $('#party_size').change(function (e) {
      const reservation_id = $('.reservation_edit').attr('data-reservation-id')
      var reservation_on = $('#date').val()
      var party_size = $('#party_size').val()
      var timeslot = $('#time').val()

      $.ajax({
        dataType: 'json',
        type: 'post',
        url: '/patron/reservations/check_inventory',
        data: {
          reservation_id: reservation_id,
          reservation_on: reservation_on,
          party_size: party_size,
          timeslot: timeslot
        },
        success: function (data) {
          console.log(data)
          console.log(data['timeslots'])
          $('#time').find('option').remove()
          var timeslots = data.timeslots
          if (timeslots.length == 0) {
            $('.timeslots').hide()
            $('.no-timeslots').show()
            $('.no-timeslots').replaceWith(
              '<p class="col-12 col-lg-6 no-timeslots">' + data.message + '</p>'
            )
          } else {
            $('#time').append(new Option('Please select a time', ''))
            for (var i = 0; i < timeslots.length; i++) {
              $('.no-timeslots').hide()
              $('.timeslots').show()
              $('#time').append(new Option(timeslots[i][0], timeslots[i][1]))
            }
          }
        },
        error: function (data) {
          console.log(data)
        }
      })
    })
  }
}
