import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['form', 'query', 'display']

  connect() {}

  search(event) {
    const query = event.target.value

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 350)
  }

  result(event) {
    const [_data, _status, xhr] = event.detail
    const data = xhr.response

    if (data.length > 0) {
      return (this.displayTarget.innerHTML = data)
    } else {
      return (this.displayTarget.innerHTML = 'No Results Found')
    }
  }

  clear() {
    this.displayTarget.innerHTML = ''
  }
}
