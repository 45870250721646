import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cart', 'edit']

  connect() {}

  updateCart(event) {
    const [_data, _status, xhr] = event.detail
    this.cartTarget.innerHTML = xhr.response

    if (event.target.tagName == 'FORM') {
      this.#resetForm(event.target)
    }
    this.#updateCartQty()
  }

  editModal(event) {
    const [_data, _status, xhr] = event.detail
    this.editTarget.innerHTML = xhr.response
    this.#setModifierCheckboxes()
  }

  updateMealPrice(event) {
    var tag = event.target.parentElement
    var modifierPrice = tag.getElementsByClassName('modifier-price')[0]
    if (typeof modifierPrice === 'undefined') {
      modifierPrice = 0
    } else {
      modifierPrice = parseFloat(modifierPrice.innerHTML.replace(' - $', ''))
    }
    var itemPriceTag =
      tag.parentElement.parentElement.getElementsByClassName('item-price')[0]
    var itemPrice = parseFloat(itemPriceTag.innerHTML.replace('$', ''))
    if (event.target.checked) {
      var total = itemPrice + modifierPrice
    } else {
      var total = itemPrice - modifierPrice
    }
    itemPriceTag.innerHTML = '$' + total.toFixed(2)
  }

  modalClosed(event) {
    this.#resetForm(event.target.parentElement.nextElementSibling)
  }

  decreaseValue(event) {
    const numberInput = event.target.parentElement.querySelector('.order-qty')
    var value = parseInt(numberInput.value, 10)
    if (isNaN(value)) value = 0
    if (value == 1) {
      numberInput.value = 1
    } else {
      numberInput.value = value - 1
    }
  }

  increaseValue(event) {
    const numberInput = event.target.parentElement.querySelector('.order-qty')
    var value = parseInt(numberInput.value, 10)
    if (isNaN(value)) value = 0
    numberInput.value = value + 1
  }

  #resetForm(formElement) {
    formElement.reset()
    const checkboxes = formElement.querySelectorAll("input[type='checkbox']")
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].disabled = false
    }
    formElement.querySelector("input[type='submit']").classList.add('disabled')

    const price = formElement.querySelector('.item-price')
    price.innerHTML = price.dataset.price
  }

  #updateCartQty() {
    var cartCount = document.getElementsByClassName('badge').length
    document.getElementById('cartQty').innerHTML = cartCount
  }

  #setModifierCheckboxes() {
    var checked = document
      .querySelector('#editModal')
      .querySelectorAll('input[type="checkbox"]:checked')
    for (let i = 0; i < checked.length; i++) {
      var max = checked[i].dataset.max
      var checkboxes = checked[i].parentElement.parentElement.querySelectorAll(
        'input[type="checkbox"]'
      )
      var checkCount = 0
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkCount++
        }
      }

      //disable unchecked boxes if max required amount is reached
      var bool = checkCount >= max
      for (let i = 0; i < checkboxes.length; i++) {
        if (!checkboxes[i].checked) {
          checkboxes[i].disabled = bool
        }
      }
    }
  }
}
