import { Controller } from "@hotwired/stimulus";

import bootstrap from "bootstrap/dist/js/bootstrap";

export default class extends Controller {
  static targets = ["orderQty", "btnCart", "itemPrice", "modifierPrice"];

  connect() {}

  updateCart(event) {
    const [_data, _status, xhr] = event.detail;
    document.getElementById("cart-offcanvas").innerHTML = xhr.response;
    if (event.target.tagName === "FORM") {
      this.resetForm(event.target);
    }
    this.updateCartQty();
  }

  newModal(event) {
    const [_data, _status, xhr] = event.detail;
    const offcanvasElement = document.getElementById("offcanvasRight");
    if (offcanvasElement) {
      const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
      if (offcanvas) {
        offcanvas.hide();
      }
    }

    const newModalContent = document.getElementById("itemModalContent");
    if (newModalContent) {
      newModalContent.innerHTML = xhr.response;
      const newModal = document.getElementById("itemModal");
      if (newModal) {
        const modal = bootstrap.Modal.getOrCreateInstance(newModal);
        modal.show();
        this.checkboxLimit();
      }
    }
  }

  updateMealPrice(event) {
    let modifierPriceElement = event.target;
    let modifierPrice = event.target.dataset.modifierPrice;
    if (modifierPriceElement && modifierPrice) {
      modifierPrice = parseFloat(modifierPrice.replace("$", ""));
      let itemPriceTag = this.itemPriceTarget;
      let itemPrice = parseFloat(itemPriceTag.innerHTML.replace("$", ""));

      let total = modifierPriceElement.checked
        ? itemPrice + modifierPrice
        : itemPrice - modifierPrice;
      itemPriceTag.innerHTML = "$" + total.toFixed(2);
    }

    this.checkboxLimit();
  }

  decreaseValue() {
    const numberInput = this.orderQtyTarget;
    let value = parseInt(numberInput.value, 10);
    if (isNaN(value)) value = 0;
    numberInput.value = value > 1 ? value - 1 : 1;
    this.updateItemPrice();
  }

  increaseValue() {
    const numberInput = this.orderQtyTarget;
    let value = parseInt(numberInput.value, 10);
    if (isNaN(value)) value = 0;
    numberInput.value = value + 1;
    this.updateItemPrice();
  }

  updateItemPrice() {
    this.itemPriceTarget.innerHTML =
      "$" +
      (this.orderQtyTarget.value * this.itemPriceTarget.dataset.price).toFixed(
        2
      );
  }

  openOffcanvas() {
    const offcanvasElement = document.getElementById("offcanvasRight");

    if (!offcanvasElement) {
      return;
    }

    let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);

    if (!offcanvasInstance) {
      offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement);
    }

    offcanvasInstance.show();
  }

  closeOffcanvas() {
    const offcanvasElement = document.getElementById("offcanvasRight");
    if (offcanvasElement) {
      const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
      if (offcanvas) {
        offcanvas.hide();
      }
    }
  }

  toastTimeout() {
    setTimeout(() => {
      const toast = document.querySelector(".toast");
      toast.classList.add('fade-out');
      if (toast) {
        toast.remove();
      }
    }, 1500);
  }

  resetForm(formElement) {
    formElement.reset();
    const checkboxes = formElement.querySelectorAll("input[type='checkbox']");
    checkboxes.forEach((checkbox) => (checkbox.disabled = false));
    formElement.querySelector("input[type='submit']").classList.add("disabled");
    const price = formElement.querySelector(".item-price");
    if (price) {
      price.innerHTML = price.dataset.price;
    }
  }

  updateCartQty() {
    const qtyElements = document.querySelectorAll(".cart-qty");

    let cartQty = 0;

    qtyElements.forEach((element) => {
      cartQty += parseInt(element.textContent, 10);
    });

    document.getElementById("cartQty").textContent = cartQty;
  }

  checkboxLimit() {
    const checkboxes = Array.from(
      document.querySelectorAll(".modifier-checkbox")
    );

    if (checkboxes.length === 0) {
      this.updateBtnState(true);
      return;
    }

    let allRequirementsMet = true;
    let groups = new Set(checkboxes.map((checkbox) => checkbox.dataset.group));

    groups.forEach((group) => {
      let groupCheckboxes = checkboxes.filter(
        (checkbox) => checkbox.dataset.group === group
      );
      let max = parseInt(groupCheckboxes[0].dataset.max);
      let min = parseInt(groupCheckboxes[0].dataset.min);
      let checkedCount = groupCheckboxes.filter((cb) => cb.checked).length;
      let unchecked = groupCheckboxes.filter((cb) => !cb.checked);
      // Disable unchecked boxes if max required amount is reached
      let disable = checkedCount >= max;
      unchecked.forEach((cb) => {
        cb.disabled = disable;
      });

      if (checkedCount < min && min > 0) {
        allRequirementsMet = false;
      }
    });
    this.updateBtnState(allRequirementsMet);
  }

  updateBtnState(requirements_met) {
    const cartBtn = document.getElementById("btnCart");
    if (!cartBtn) {
      return;
    }
    if (requirements_met) {
      cartBtn.classList.remove("disabled");
    } else {
      cartBtn.classList.add("disabled");
    }
  }
}
