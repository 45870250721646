import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import bootstrap from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ['menuForm', 'mealForm']

  connect() {
    this.initializeTooltips()
  }

  updateMenu(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = xhr.response
    this.mealFormTarget.innerHTML = ''

    this.removeClickedClass()
    this.initializeTooltips()
  }

  updateMeal(event) {
    const [_data, _status, xhr] = event.detail
    this.mealFormTarget.innerHTML = xhr.response

    var mealModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById('mealModal')
    )
    mealModal.show()

    this.removeClickedClass()
    event.target.classList.add('clicked')
    this.initializeTooltips()
  }

  formSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = xhr.response

    if (_status != 'OK') {
      return false
    }

    this.updateMenuTabs()
    this.triggerToast()
    this.refreshMenuAccordion()
    this.initializeTooltips()
  }

  refreshMenuAccordion() {
    const menuAccordion = document.querySelector('#menuAccordion')
    const openAccordionMenu = menuAccordion.querySelector('.collapse.show')
    const openAccordionMenuId = openAccordionMenu ? openAccordionMenu.id : null
    const scheduleName = menuAccordion.getAttribute('data-menu-schedule-name')

    this.updateMenuAccordion(scheduleName, openAccordionMenuId)
  }

  newMenuSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = xhr.response

    if (_status != 'OK') {
      return false
    }

    var formModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById('newMenuModal')
    )
    formModal.hide()

    this.updateMenuTabs()
    this.triggerToast()
    this.refreshMenuAccordion()
    this.initializeTooltips()
  }

  mealFormSubmit(event) {
    const [_data, _status, xhr] = event.detail
    this.mealFormTarget.innerHTML = xhr.response

    if (_status != 'OK') {
      return false
    }

    var mealModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById('mealModal')
    )
    mealModal.hide()

    this.triggerToast()
    this.refreshMenuAccordion()
    this.initializeTooltips()
  }

  removeMenu(event) {
    const [_data, _status, xhr] = event.detail
    this.menuFormTarget.innerHTML = ''

    document.querySelector('#accordion_' + String(_data['menu'])).remove()
    this.triggerToast()
  }

  removeMeal(event) {
    const [_data, _status, xhr] = event.detail
    this.mealFormTarget.innerHTML = ''

    var mealModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById('mealModal')
    )
    mealModal.hide()

    document.querySelector('#accordion_' + String(_data['menu_item'])).remove()
    this.triggerToast()
  }

  triggerToast(status) {
    var toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.success')
    )
    toast.show()
  }

  removeClickedClass() {
    const boxes = document.querySelectorAll('.nav-link-light.clicked')
    boxes.forEach((box) => {
      box.classList.remove('clicked')
    })
  }

  updateMenuTabs() {
    const menuTabsElement = document.getElementById('menuTabs')
    if (menuTabsElement) {
      const updateUrl = menuTabsElement.getAttribute('data-update-url')
      if (updateUrl) {
        $.ajax({
          url: updateUrl,
          type: 'get',
          success: (data) => {
            menuTabsElement.innerHTML = data
            this.initializeTooltips()
          },
          error: function (data) {
            console.error('Error updating menu tabs:', data)
          }
        })
      } else {
        console.warn('Menu schedule does not exist; update URL not available.')
      }
    }
  }

  checkNewMenu() {
    var scheduleName = document.querySelector('.tab.btn-light-red').innerHTML

    $.ajax({
      url: '/restaurant/settings/online_orders/menus/check_new',
      type: 'get',
      data: { schedule_name: scheduleName },
      success: (data) => {
        if (data['schedule_match'] == null) {
          document.querySelector('#menuForm').innerHTML = data
          this.updateMenuAccordion(scheduleName)
          this.initializeTooltips()
        }
      },
      error: function (data) {}
    })
  }

  updateMenuAccordion(scheduleName, openAccordionMenuId = null) {
    const data = {
      schedule_name: scheduleName,
      open_accordion_menu_id: openAccordionMenuId
    }

    $.ajax({
      url: '/restaurant/settings/online_orders/menus/update_menu_accordion',
      type: 'get',
      data: data,
      success: (data) => {
        document.querySelector('#menuAccordion').innerHTML = data
        this.initializeTooltips()
      },
      error: function (data) {}
    })
  }

  initializeTooltips() {
    const tooltipTriggerList = Array.from(
      this.element.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  navigate(event) {
    event.preventDefault()
    const modal = bootstrap.Modal.getInstance(
      document.getElementById('mealModal')
    )
    modal.hide()
    window.location.href = event.target.href
  }
}
