import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tableHoursButton']

  connect() {
    this.tableHoursButtonTargets.forEach((button) => {
      button.addEventListener('click', this.toggleTableHours.bind(this))
    })
  }

  toggleTableHours(event) {
    const dayName = event.target.dataset.day
    const midOpenId = `mid-open-${dayName}`
    const midCloseId = `mid-close-${dayName}`
    const resetId = `reset-${dayName}`

    const midOpen = document.getElementById(midOpenId)
    const midClose = document.getElementById(midCloseId)
    const reset = document.getElementById(resetId)

    if (midOpen && midClose && reset) {
      midOpen.classList.toggle('visually-hidden')
      midClose.classList.toggle('visually-hidden')
      reset.classList.toggle('visually-hidden')
    }
  }
}
