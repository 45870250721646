import { Controller } from '@hotwired/stimulus'
import { Tooltip, Modal } from 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ['row']

  connect() {
    console.log('reservation_status_controller connected')
  }

  toggleActive(event) {
    event.target.classList.toggle('active')
  }

  // Handle any AJAX errors
  error(event) {
    alert('Error updating reservation status', event)
  }

  // Before the AJAX request is sent
  beforeRequest(event) {
    const element = event.target
    const tooltip = Tooltip.getInstance(element)
    if (tooltip) {
      tooltip.hide()
    }
  }

  // Handle the AJAX success response
  success(event) {
    const [data, status, xhr] = event.detail

    // Parse the JSON response
    const response = JSON.parse(xhr.response)

    // Access additional data
    const options = response.options

    // Update the row content
    const controlsRow = document.querySelector(`#row-${options.id}`)
    if (controlsRow) {
      controlsRow.innerHTML = response.html
    }

    // Now you can use reservationId and status as needed
    this.finalizeUpdate(options)
  }

  finalizeUpdate(options) {
    // Initialize tooltips on new elements
    const row = document.getElementById('row-' + options.id)
    if (row) {
      const tooltipTriggerList = [].slice.call(
        row.querySelectorAll('[data-bs-toggle="tooltip"]')
      )
      tooltipTriggerList.map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      )
    }

    // Handle status classes
    const undo = row.parentNode.querySelectorAll('.active').length <= 0
    const fullTr = document.getElementById('row_' + options.id)

    if (undo) {
      fullTr.classList.remove(options.status)
    } else {
      fullTr.classList.add(options.status)

      // Handle fade out for specific statuses
      const fadeOutStatuses = [
        'row--status-cancelled',
        'row--status-completed',
        'row--status-no_show'
      ]

      const hideCompleted = !document
        .getElementById('hideCompleted')
        .classList.contains('d-none')

      if (fadeOutStatuses.includes(options.status) && hideCompleted) {
        this.fadeOut(fullTr)
      }
    }

    // Update status text
    const statusText = row.parentNode.querySelectorAll('#statusText')[0]
    if (statusText && options.statustext) {
      statusText.innerText = options.statustext
    }

    // Show completed modal if needed
    if (options.complete !== undefined && !parseInt(options.complete)) {
      const completedModal = new Modal(
        document.getElementById('completedModal')
      )
      completedModal.show()
    }
  }

  fadeOut(element) {
    element.style.transition = 'opacity 600ms'
    element.style.opacity = 0

    setTimeout(() => {
      element.style.display = 'none'
    }, 600)

    element.style.opacity = 100
  }
}
