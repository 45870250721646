import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['restaurantIds']

  update() {
    // Get all checked "plans" checkboxes
    const plans = Array.from(
      this.element.querySelectorAll(
        "input[name='filter_criteria[plans][]']:checked"
      )
    ).map((checkbox) => checkbox.value)

    // Get all checked "features" checkboxes
    const features = Array.from(
      this.element.querySelectorAll(
        "input[name='filter_criteria[features][]']:checked"
      )
    ).map((checkbox) => checkbox.value)

    // Send the data to the server
    fetch('/admin/filter_notifications', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']")
          .content
      },
      body: JSON.stringify({
        filter_criteria: {
          plans: plans,
          features: features
        }
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        this.updateRestaurantCheckboxes(data.restaurant_ids)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  updateRestaurantCheckboxes(restaurantIds) {
    // Uncheck all restaurant checkboxes first
    this.restaurantIdsTarget
      .querySelectorAll("input[name='filter_criteria[restaurant_ids][]']")
      .forEach((checkbox) => {
        checkbox.checked = false
      })

    // Check only the boxes matching the returned IDs
    restaurantIds.forEach((id) => {
      const checkbox = this.restaurantIdsTarget.querySelector(
        `input[name='filter_criteria[restaurant_ids][]'][value='${id}']`
      )
      if (checkbox) {
        checkbox.checked = true
      }
    })
  }
}
