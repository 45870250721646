import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'mealSearch',
    'topItemFilter',
    'houseSpecialFilter',
    'glutenFreeFilter',
    'vegetarianFilter',
    'veganFilter'
  ]

  applyFilters(event) {
    event.preventDefault()
    const searchValue = this.mealSearchTarget.value.toLowerCase()
    const filters = this.getFilters()

    const mealItems = document.querySelectorAll(
      '[data-online-orders-filter-target="mealItem"]'
    )
    mealItems.forEach((mealItem) => {
      const mealAttributes = this.getMealAttributes(mealItem)
      const matchesFilters = this.matchesFilters(
        searchValue,
        filters,
        mealAttributes
      )
      mealItem.style.display = matchesFilters ? 'block' : 'none'
    })

    this.updateFilterCount(filters, searchValue)
    this.hideEmptySchedules()
    this.closeModal()
  }

  clearFilters(event) {
    this.mealSearchTarget.value = ''
    if (this.hasTopItemFilterTarget) this.topItemFilterTarget.checked = false
    if (this.hasHouseSpecialFilterTarget)
      this.houseSpecialFilterTarget.checked = false
    if (this.hasGlutenFreeFilterTarget)
      this.glutenFreeFilterTarget.checked = false
    if (this.hasVegetarianFilterTarget)
      this.vegetarianFilterTarget.checked = false
    if (this.hasVeganFilterTarget) this.veganFilterTarget.checked = false

    this.applyFilters(event)
  }

  updateFilterCount(filters, searchValue) {
    const filterCountElement = document.getElementById('filterCount')
    const filterCount =
      (filters.glutenFree ? 1 : 0) +
      (filters.vegetarian ? 1 : 0) +
      (filters.vegan ? 1 : 0) +
      (filters.houseSpecial ? 1 : 0) +
      (filters.topItem ? 1 : 0) +
      (searchValue ? 1 : 0)

    if (filterCount > 0) {
      filterCountElement.classList.remove('d-none')
      filterCountElement.textContent = filterCount
    } else {
      filterCountElement.classList.add('d-none')
      filterCountElement.textContent = ''
    }
  }

  closeModal() {
    const modal = document.getElementById('filterModal')
    if (modal) {
      const bootstrapModal = bootstrap.Modal.getOrCreateInstance(modal)
      if (bootstrapModal) {
        bootstrapModal.hide()
      }
    }
  }

  hideEmptySchedules() {
    this.hideEmptyContainers('[id^="menu-schedule"]')
    this.hideEmptyContainers('[id^="menu"]')
  }

  hideEmptyContainers(selector) {
    const containers = document.querySelectorAll(selector)
    containers.forEach((container) => {
      const mealItems = container.querySelectorAll(
        '[data-online-orders-filter-target="mealItem"]'
      )
      const hasVisibleItems = Array.from(mealItems).some(
        (item) => item.style.display === 'block'
      )
      container.style.display = hasVisibleItems ? 'block' : 'none'
    })
  }

  getFilters() {
    return {
      glutenFree: this.hasGlutenFreeFilterTarget
        ? this.glutenFreeFilterTarget.checked
        : false,
      vegetarian: this.hasVegetarianFilterTarget
        ? this.vegetarianFilterTarget.checked
        : false,
      vegan: this.hasVeganFilterTarget ? this.veganFilterTarget.checked : false,
      houseSpecial: this.hasHouseSpecialFilterTarget
        ? this.houseSpecialFilterTarget.checked
        : false,
      topItem: this.hasTopItemFilterTarget
        ? this.topItemFilterTarget.checked
        : false
    }
  }

  getMealAttributes(mealItem) {
    return {
      name: mealItem.getAttribute('data-name').toLowerCase(),
      description:
        mealItem.getAttribute('data-description')?.toLowerCase() || '',
      glutenFree: mealItem.getAttribute('data-gluten-free') === 'true',
      vegetarian: mealItem.getAttribute('data-vegetarian') === 'true',
      vegan: mealItem.getAttribute('data-vegan') === 'true',
      houseSpecial: mealItem.getAttribute('data-house-special') === 'true',
      topItem: mealItem.getAttribute('data-top-item') === 'true'
    }
  }

  matchesFilters(searchValue, filters, mealAttributes) {
    const matchesSearch =
      mealAttributes.name.includes(searchValue) ||
      mealAttributes.description.includes(searchValue) ||
      searchValue === ''
    const matchesGlutenFree = !filters.glutenFree || mealAttributes.glutenFree
    const matchesVegetarian = !filters.vegetarian || mealAttributes.vegetarian
    const matchesVegan = !filters.vegan || mealAttributes.vegan
    const matchesHouseSpecial =
      !filters.houseSpecial || mealAttributes.houseSpecial
    const matchesTopItem = !filters.topItem || mealAttributes.topItem

    return (
      matchesSearch &&
      matchesGlutenFree &&
      matchesVegetarian &&
      matchesVegan &&
      matchesHouseSpecial &&
      matchesTopItem
    )
  }
}
