import { Controller } from "@hotwired/stimulus";

import bootstrap from "bootstrap/dist/js/bootstrap";

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element);
    this.showModal();
  }

  showModal() {
    this.modal.show();
  }

  checkDiscountLink() {
    const discountNoticeModal = document.getElementById("discountNoticeModal");
    if (discountNoticeModal) {
      const modal = new bootstrap.Modal(discountNoticeModal);
      modal.show();
    }
  }
}
