import { Controller } from '@hotwired/stimulus'

import { fabric } from 'fabric'
import bootstrap from 'bootstrap/dist/js/bootstrap'
import { SeatPositionCalculator } from 'packs/table_management/seat_position_calculator'

export default class extends Controller {
  connect() {}

  addSquare() {
    var canvas = document.getElementById('canvas').fabric

    // Current viewport transformation
    var vpt = canvas.viewportTransform
    var zoom = canvas.getZoom()

    // Calculate the top left position
    // X position is just offset by the current pan, divided by the zoom with 10px padding
    var topLeftX = 75 / 2 - vpt[4] / zoom + 10
    // Y position is half the height of the rectangle to center it vertically around the top edge
    var topLeftY = -vpt[5] / zoom + 75 / 2 + 10 // 75 is the height of the rectangle with 10px padding

    var square = new fabric.Rect({
      top: topLeftY,
      left: topLeftX,
      width: 75,
      height: 75,
      fill: '#33AB26',
      kind: 'table',
      id: '',
      number: '',
      seats: '2',
      originX: 'center',
      originY: 'center',
      shape: 11,
      lockScalingX: true,
      lockScalingY: true
    })

    canvas.add(square)
    this.#addchairs(canvas, square)
  }

  addRectangle() {
    var canvas = document.getElementById('canvas').fabric

    // Current viewport transformation
    var vpt = canvas.viewportTransform
    var zoom = canvas.getZoom()

    // Calculate the top left position
    // X position is just offset by the current pan, divided by the zoom with 10px padding
    var topLeftX = 75 / 2 - vpt[4] / zoom + 10
    // Y position is half the height of the rectangle to center it vertically around the top edge
    var topLeftY = -vpt[5] / zoom + 75 / 2 + 10 // 75 is the height of the rectangle with 10px padding

    var rectangle = new fabric.Rect({
      top: topLeftY,
      left: topLeftX,
      width: 100,
      height: 75,
      fill: '#33AB26',
      kind: 'table',
      id: '',
      number: '',
      seats: '4',
      originX: 'center',
      originY: 'center',
      shape: 12,
      lockScalingX: true,
      lockScalingY: true
    })

    canvas.add(rectangle)
    this.#addchairs(canvas, rectangle)
  }

  addCircle() {
    var canvas = document.getElementById('canvas').fabric
    // Current viewport transformation
    var vpt = canvas.viewportTransform
    var zoom = canvas.getZoom()

    // Calculate the top left position
    // X position is just offset by the current pan, divided by the zoom with 10px padding
    var topLeftX = 75 / 2 - vpt[4] / zoom + 10
    // Y position is half the height of the rectangle to center it vertically around the top edge
    var topLeftY = -vpt[5] / zoom + 75 / 2 + 10 // 75 is the height of the rectangle with 10px padding

    var circle = new fabric.Circle({
      top: topLeftY,
      left: topLeftX,
      radius: 37.5,
      fill: '#33AB26',
      kind: 'table',
      id: '',
      number: '',
      seats: '2',
      originX: 'center',
      originY: 'center',
      shape: 10,
      lockScalingX: true,
      lockScalingY: true
    })
    var canvas = document.getElementById('canvas').fabric
    canvas.add(circle)
    this.#addchairs(canvas, circle)
  }

  addBar() {
    var canvas = document.getElementById('canvas').fabric

    // Current viewport transformation
    var vpt = canvas.viewportTransform
    var zoom = canvas.getZoom()

    // Calculate the top left position
    // X position is just offset by the current pan, divided by the zoom with 10px padding
    var topLeftX = 75 / 2 - vpt[4] / zoom + 10
    // Y position is half the height of the rectangle to center it vertically around the top edge
    var topLeftY = -vpt[5] / zoom + 75 / 2 + 10 // 75 is the height of the rectangle with 10px padding

    var rectangle = new fabric.Rect({
      top: topLeftY,
      left: topLeftX,
      width: 75,
      height: 50,
      fill: '#33AB26',
      kind: 'bar',
      id: '',
      number: '',
      seats: '1',
      originX: 'center',
      originY: 'center',
      rx: 10,
      ry: 10,
      shape: 13,
      lockScalingX: true,
      lockScalingY: true
    })

    canvas.add(rectangle)
    this.#addchairs(canvas, rectangle)
  }

  addDivider() {
    var canvas = document.getElementById('canvas').fabric
    // Current viewport transformation
    var vpt = canvas.viewportTransform
    var zoom = canvas.getZoom()

    // Calculate the top left position
    // X position is just offset by the current pan, divided by the zoom with 10px padding
    var topLeftX = 75 / 2 - vpt[4] / zoom + 10
    // Y position is half the height of the rectangle to center it vertically around the top edge
    var topLeftY = -vpt[5] / zoom + 75 / 2 + 10 // 75 is the height of the rectangle with 10px padding

    var divider = new fabric.Rect({
      top: topLeftY,
      left: topLeftX,
      width: 25,
      height: 200,
      fill: '#e1e2e5',
      kind: 'divider',
      id: '',
      originX: 'center',
      originY: 'center',
      shape: 14
    })
    var canvas = document.getElementById('canvas').fabric
    canvas.add(divider)
  }

  addExistingTable(event) {
    const [data, _status, _xhr] = event.detail
    console.log(data)

    var canvas = document.getElementById('canvas').fabric
    var table = new fabric.Rect({
      number: data.table_attributes.number,
      seats: data.table_attributes.seats,
      section_id: data.table_attributes.section_id,
      width: data.table_attributes.width,
      height: data.table_attributes.height,
      shape: data.table_attributes.shape,
      table_id: data.table_attributes.id,
      status: data.table_attributes.status,
      kind: data.table_attributes.kind,
      angle: data.table_configuration_attributes.angle,
      backgroundColor: data.table_configuration_attributes.backgroundColor,
      fill: data.table_configuration_attributes.fill,
      fillRule: data.table_configuration_attributes.fillRule,
      flipX: data.table_configuration_attributes.flipX,
      flipY: data.table_configuration_attributes.flipY,
      globalCompositeOperation:
        data.table_configuration_attributes.globalCompositeOperation,
      height: data.table_configuration_attributes.height,
      left: data.table_configuration_attributes.left,
      opacity: data.table_configuration_attributes.opacity,
      originX: data.table_configuration_attributes.originX,
      originY: data.table_configuration_attributes.originY,
      paintFirst: data.table_configuration_attributes.paintFirst,
      rx: data.table_configuration_attributes.rx,
      ry: data.table_configuration_attributes.ry,
      scaleX: data.table_configuration_attributes.scaleX,
      scaleY: data.table_configuration_attributes.scaleY,
      shadow: data.table_configuration_attributes.shadow,
      skewX: data.table_configuration_attributes.skewX,
      skewY: data.table_configuration_attributes.skewY,
      stroke: data.table_configuration_attributes.stroke,
      strokeDashArray: data.table_configuration_attributes.strokeDashArray,
      strokeDashOffset: data.table_configuration_attributes.strokeDashOffset,
      strokeLineCap: data.table_configuration_attributes.strokeLineCap,
      strokeLineJoin: data.table_configuration_attributes.strokeLineJoin,
      strokeMiterLimit: data.table_configuration_attributes.strokeMiterLimit,
      strokeUniform: data.table_configuration_attributes.strokeUniform,
      strokeWidth: data.table_configuration_attributes.strokeWidth,
      top: data.table_configuration_attributes.top,
      type: data.table_configuration_attributes.type,
      visible: data.table_configuration_attributes.visible,
      width: data.table_configuration_attributes.width,
      radius: data.table_configuration_attributes.radius,
      startAngle: data.table_configuration_attributes.startAngle,
      endAngle: data.table_configuration_attributes.endAngle,
      lockScalingX: data.table_configuration_attributes.lockScalingX,
      lockScalingY: data.table_configuration_attributes.lockScalingY,
      shape: data.table_attributes.shape
    })

    canvas.add(table)
    this.#addchairs(canvas, table)
  }

  removeMultipleTables() {
    const floorID = document.querySelector('#canvas').dataset.id
    const canvas = document.getElementById('canvas').fabric
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      // Multiple Selected
      activeObject.forEachObject(function (obj) {
        if (obj.hasOwnProperty('kind')) {
          removeTable(obj)
        }
      })
    } else if (activeObject) {
      // One Selected
      removeTable(activeObject)
    } else {
      // None Selected
      const toast = bootstrap.Toast.getOrCreateInstance(
        document.querySelector('.error')
      )
      document.querySelector('.error').querySelector('.toast-body').innerHTML =
        'You must select a table to delete.'
      toast.show()
      return false
    }
    function removeTable(obj) {
      if (obj.id == '') {
        let numberGroup = findGroupByTableNumber(obj.number)
        canvas.remove(numberGroup)
        canvas.remove(obj)
        removeChairs(obj)
        canvas.discardActiveObject()
        canvas.requestRenderAll()
      } else {
        let idType = obj.id
        if (obj.kind == 'table' || obj.kind == 'bar') {
          idType = obj.table_id
        }
        $.ajax({
          dataType: 'json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'X-CSRF-Token',
              $('meta[name="csrf-token"]').attr('content')
            )
          },
          type: 'DELETE',
          url: `/restaurant/floor_plans/${floorID}/${obj.kind}s/${idType}`,
          data: {
            table_configuration_id: obj.id
          },
          success: function (data) {
            let numberGroup = findGroupByTableId(canvas, obj.id)
            canvas.remove(numberGroup)
            canvas.remove(obj)
            removeChairs(obj)
            canvas.discardActiveObject()
            canvas.requestRenderAll()
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
    }

    function findGroupByTableId(canvas, tableId) {
      let foundGroup = null

      canvas.forEachObject(function (object) {
        if (object.type === 'group' && object.tableId === tableId) {
          foundGroup = object
          return false // Break the loop
        }
      })

      return foundGroup
    }

    function findGroupByTableNumber(tableNumber) {
      let foundGroup = null

      canvas.forEachObject(function (object) {
        if (object.type === 'group' && object.tableNumber === tableNumber) {
          foundGroup = object
          return false // Break the loop
        }
      })

      return foundGroup
    }

    function removeChairs(table) {
      let seatRef = table.id || table.seatRef

      canvas.forEachObject(function (object) {
        if (object.kind === 0 && object.tableId === seatRef) {
          canvas.remove(object)
        }
      })
    }
  }

  increaseTable(event) {
    event.preventDefault()

    let numberInput = document.querySelector('#seats')
    numberInput.value = this.#increasedValue(numberInput.value)

    this.#updateTableSize(numberInput.value)
  }

  decreaseTable(event) {
    event.preventDefault()

    let numberInput = document.querySelector('#seats')
    numberInput.value = this.#decreasedValue(numberInput.value)

    this.#updateTableSize(numberInput.value)
  }

  #increasedValue(inputValue) {
    let value = parseInt(inputValue, 10)
    if (isNaN(value)) return 0
    return value + 1
  }

  #decreasedValue(inputValue) {
    let value = parseInt(inputValue, 10)
    if (isNaN(value)) value = 0
    if (value == 1) return 1
    return value - 1
  }

  #updateTableSize(value) {
    const canvas = document.getElementById('canvas').fabric
    const activeObject = canvas.getActiveObject()

    switch (activeObject.shape) {
      case 12: //RECTANGLE
        activeObject.set({
          width: value * 25,
          seats: value
        })
        break
      case 13: // BAR
        activeObject.set({
          width: value * 50,
          seats: value
        })
        break

      case 11: // SQUARE
        let dimensions = this.#tableSquareDimensions(value)
        activeObject.set({
          width: dimensions['width'],
          height: dimensions['height'],
          seats: value
        })
        break

      case 10: // CIRCLE
        let radius = this.#tableCircleDimensions(value)
        activeObject.set({
          radius: radius,
          seats: value
        })
        break

      default:
        break
    }

    this.#addchairs(canvas, activeObject)
  }

  #tableSquareDimensions(value) {
    let sizes = {
      width: 75,
      height: 75
    }

    if (value >= 5 && value < 9) {
      sizes['width'] = 87.5
      sizes['height'] = 87.5
    } else if (value >= 9) {
      sizes['width'] = 100
      sizes['height'] = 100
    }

    return sizes
  }

  #tableCircleDimensions(value) {
    let radius = 37.5

    if (value >= 5 && value < 9) {
      radius = 43.25
    } else if (value >= 9) {
      radius = 50
    }

    return radius
  }

  #addchairs(canvas, table) {
    let seatRef = table.id || table.seatRef
    if (!seatRef) {
      seatRef = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000
      table.set('seatRef', seatRef)
    }

    let seatPositionCalculator = new SeatPositionCalculator(
      seatRef,
      table.seats,
      table.shape,
      table.left,
      table.top,
      table.height,
      table.width,
      table.radius,
      table.scaleX,
      table.scaleY,
      table.angle,
      table
    )

    canvas.forEachObject(function (object) {
      if (
        object.type === 'group' &&
        object.kind === 0 &&
        object.tableId === seatRef
      ) {
        canvas.remove(object)
      }
    })

    seatPositionCalculator.addSeatsToCanvas()
  }
}
