import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = {
    offset: Number
  }

  loadMore(event) {
    event.preventDefault()

    const url = new URL(window.location.href)
    url.pathname = '/how_tos/load_more'
    url.searchParams.set('offset', this.offsetValue)

    // Preserve any existing query parameters
    const category = new URLSearchParams(window.location.search).get('category')
    const userType = new URLSearchParams(window.location.search).get(
      'user_type'
    )
    if (category) url.searchParams.set('category', category)
    if (userType) url.searchParams.set('user_type', userType)

    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
      .then((response) => response.text())
      .then((html) => {
        document
          .getElementById('how-tos-list')
          .insertAdjacentHTML('beforeend', html)
        this.offsetValue += 1
      })
  }
}
