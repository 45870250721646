import { Controller } from "@hotwired/stimulus";
import JobProcessingChannel from "../channels/job_processing_channel";

export default class extends Controller {
  static targets = ["progressBar", "message", "toast"];

  connect() {
    this.fileId = this.data.get("file-id");
  }

  subscribeToStatus() {
    this.toastTarget.classList.remove("d-none");
    this.subscription = JobProcessingChannel.subscribe(this.fileId, {
      received: (data) => this.updateProgress(data),
      connected: () => console.log("Channel connected"),
      disconnected: () => console.log("Channel disconnected"),
    });
  }

  updateProgress(data) {
    this.progressBarTarget.style.width = `${data.progress}%`;
    this.messageTarget.textContent = data.message;

    if (data.progress === 100) {
      this.progressBarTarget.classList.add("bg-success");
      this.subscription?.unsubscribe();
    }
  }

  hideToast() {
    this.progressBarTarget.classList.remove("bg-success");
    this.messageTarget.textContent = "";
    this.progressBarTarget.style.width = "0%";
    this.toastTarget.classList.add("d-none");
  }

  disconnect() {
    this.subscription?.unsubscribe();
  }
}
