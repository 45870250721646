import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['noteList', 'noteBody']

  connect() {}

  createSuccess(event) {
    const [_data, _status, xhr] = event.detail

    this.noteListTarget.innerHTML = xhr.response
    this.noteBodyTarget.value = ''

    $('#customerNoteModal').modal('hide')
    $('#internalNoteModal').modal('hide')
  }
}
