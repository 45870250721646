import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'plainTextField', 'richTextField']

  connect() {
    this.updateFieldVisibility()
  }

  toggle() {
    this.updateFieldVisibility()
    this.syncContent()
  }

  updateFieldVisibility() {
    if (this.toggleTarget.checked) {
      this.plainTextFieldTarget.style.display = 'none'
      this.richTextFieldTarget.style.display = 'block'
    } else {
      this.plainTextFieldTarget.style.display = 'block'
      this.richTextFieldTarget.style.display = 'none'
    }
  }

  syncContent() {
    const plainText = this.plainTextFieldTarget.querySelector('textarea').value
    const richTextEditor = this.richTextFieldTarget.querySelector('trix-editor')

    if (this.toggleTarget.checked) {
      if (
        !richTextEditor.editor.getDocument().toString().trim() &&
        plainText.trim()
      ) {
        richTextEditor.editor.loadHTML(plainText)
      }
    } else {
      const richTextContent = richTextEditor.editor.getDocument().toString()
      const plainTextArea = this.plainTextFieldTarget.querySelector('textarea')

      if (!plainTextArea.value.trim() && richTextContent.trim()) {
        plainTextArea.value = this.convertRichTextToPlainText(richTextContent)
      }
    }
  }

  convertRichTextToPlainText(richText) {
    let plainText = richText
      .replace(/<[^>]+>/g, ' ')
      .replace(/\s+/g, ' ')
      .trim()

    plainText = plainText.replace(/([a-zA-Z0-9])\s*(?=<)/g, '$1 ')

    return plainText
  }
}
