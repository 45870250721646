import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["partialToUpdate", "modalForm", "loadIndicator"];

  updateHtml(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;
  }

  updateHtmlFromJson(event) {
    const [_data, _status, xhr] = event.detail;
    const response = JSON.parse(xhr.response);
    this.partialToUpdateTarget.innerHTML = response.payload;
  }

  updateDataTable(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    let sortingIndex = this.partialToUpdateTarget.dataset.sorting_index || 0;

    $("#dataTable").DataTable({
      paging: this.partialToUpdateTarget.dataset.paging === "true",
      searching: this.partialToUpdateTarget.dataset.scrolling === "true",
      columnDefs: [{ type: "date-range", targets: 0 }],
      aaSorting: [[parseInt(sortingIndex), "desc"]],
    });
  }

  initializeDataTable(event) {
    console.log("initializeDataTable");

    if (event) {
      const [_data, _status, xhr] = event.detail;
      this.partialToUpdateTarget.innerHTML = xhr.response;
    }

    if ($("#dataTable").length) {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().destroy();
      }

      $("#dataTable").DataTable({
        pageLength: 50,
        info: false,
        aaSorting: [[0, "asc"]],
        stateSave: false,
        initComplete: function () {
          $("#tableLoadingSpinner").hide();
          $("#tableContainer").fadeIn();
        },
      });
    }

    if (this.hasLoadIndicatorTarget) {
      this.hideLoader();
    }
  }

  updateDataTableRow(event) {
    // Expects json: {paylod: "", sort: ""}
    const [_data, _status, xhr] = event.detail;

    // Parse the JSON response
    const response = JSON.parse(xhr.response);
    const { payload, sort } = response;

    // Update the target element's inner HTML
    this.partialToUpdateTarget.innerHTML = payload;

    // Update the target element's data-sort attribute
    this.partialToUpdateTarget.setAttribute("data-sort", sort);

    // Refresh DataTable's cache for the updated row
    const table = $("#dataTable").DataTable();
    table.row(this.partialToUpdateTarget.closest("tr")).invalidate(); // Target the row containing the updated cell
    table.draw(); // Re-draw the table to reflect changes
  }

  ajaxForm(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    var ajaxModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("ajaxModal")
    );

    this.#instantiateFlatpickr();
    ajaxModal.show();
  }

  clearHtml() {
    this.partialToUpdateTarget.innerHTML = "";
  }

  closeModal(event) {
    const [data, _status, _xhr] = event.detail;

    var modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById(data.modalId)
    );

    modal.hide();
  }

  successToast() {
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector(".success")
    ).show();
  }

  closeModalSuccess(event) {
    this.clearHtml();
    this.closeModal(event);
    this.successToast();
  }

  ajaxFlatpickrMultiForm(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    flatpickr(".flatpickr-multi", {
      altInput: true,
      mode: "multiple",
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      minDate: "today",
      disableMobile: true,
    });

    var ajaxModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("ajaxModal")
    );
    ajaxModal.show();
  }

  ajaxFlatpickrTimes(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    flatpickr(".flatpickr-times", {
      altInput: true,
      altFormat: "F j, Y h:i K", // Display format with time
      dateFormat: "Y-m-d H:i", // Submission format with time
      enableTime: true, // Enable time selection
      time_24hr: false, // Use 12-hour format
      disableMobile: true,
      minuteIncrement: 15,
    });

    var ajaxModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("ajaxModal")
    );
    ajaxModal.show();
  }

  #instantiateFlatpickr() {
    flatpickr(".flatpickr", {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      disableMobile: true,
      minDate: "today",
    });
  }

  resetModal() {
    // Clear the content of the modal body
    this.partialToUpdateTarget.innerHTML = `
      <div class="d-flex justify-content-center align-items-center" style="height: 500px;">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    `;
  }

  instantiateFlatpickrTimes() {
    flatpickr(this.partialToUpdateTarget.querySelector(".flatpickr-times"), {
      altInput: true,
      altFormat: "F j, Y h:i K",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
      time_24hr: false,
      disableMobile: true,
    });
  }

  hideLoader() {
    if (this.hasLoadIndicatorTarget) {
      this.loadIndicatorTarget.classList.add("d-none");
    }
  }

  showLoader() {
    this.loadIndicatorTarget.classList.remove("d-none");
  }

  fetchForm(event) {
    const [_data, _status, xhr] = event.detail;
    this.modalFormTarget.innerHTML = xhr.response;

    // Show the modal after the form is populated
    const modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("editModal")
    );
    modal.show();
  }

  handleError(event) {
    const [_data, _status, xhr] = event.detail;
    this.modalFormTarget.innerHTML = xhr.response;
  }

  handleSuccess(event) {
    const modal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById("editModal")
    );
    modal.hide();
    // this.successToast();
  }
}
