import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  updateTableRow(event) {
    const [_data, _status, xhr] = event.detail
    const tableId = event.currentTarget.dataset.tableid // Retrieve the table ID
    const tableRowElement = document.querySelector(`#table_row_${tableId}`)

    if (tableRowElement) {
      tableRowElement.innerHTML = xhr.response
    }

    this.closeOpenModal()

    const url = `/restaurant/table_blocks/${tableId}/update_buttons`

    fetch(url, {
      method: 'GET', // Or "POST" if required by your endpoint
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name=csrf-token]').content // Include CSRF token for Rails
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.text() // Get the response as text (HTML)
      })
      .then((html) => {
        const buttonContainerElement = document.querySelector(
          `#button_container_${tableId}`
        )
        if (buttonContainerElement) {
          buttonContainerElement.innerHTML = html
          this.restoreTooltips()
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
      })
  }

  closeOpenModal() {
    // Find the currently open modal by class names 'modal' and 'show'
    const openModal = document.querySelector('.modal.show')

    if (openModal) {
      // Get or create the Bootstrap modal instance
      const modalInstance = bootstrap.Modal.getOrCreateInstance(openModal)

      // Hide the modal
      modalInstance.hide()
    }
  }

  restoreTooltips() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }
}
