import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reservations', 'waitlist', 'all']

  connect() {}

  disconnect() {}

  loadReservations(event) {
    const [_data, _status, xhr] = event.detail
    this.reservationsTarget.innerHTML = xhr.response

    document.querySelector('#waitlist').classList.add('d-none')
    document.querySelector('#all').classList.add('d-none')
    document.querySelector('#reservations').classList.remove('d-none')

    document.querySelector('#waitlist').dataset.open = false
    document.querySelector('#all').dataset.open = false
    document.querySelector('#reservations').dataset.open = true

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })

    this.#setupDrag()
  }

  loadWaitlist(event) {
    const [_data, _status, xhr] = event.detail
    this.waitlistTarget.innerHTML = xhr.response

    document.querySelector('#reservations').classList.add('d-none')
    document.querySelector('#all').classList.add('d-none')
    document.querySelector('#waitlist').classList.remove('d-none')

    document.querySelector('#waitlist').dataset.open = true
    document.querySelector('#all').dataset.open = false
    document.querySelector('#reservations').dataset.open = false

    this.#setupDrag()
  }

  loadAll(event) {
    const [_data, _status, xhr] = event.detail
    this.allTarget.innerHTML = xhr.response
    this.reservationsTarget.innerHTML = ''
    this.waitlistTarget.innerHTML = ''

    document.querySelector('#waitlist').classList.add('d-none')
    document.querySelector('#reservations').classList.add('d-none')
    document.querySelector('#all').classList.remove('d-none')

    document.querySelector('#waitlist').dataset.open = false
    document.querySelector('#all').dataset.open = true
    document.querySelector('#reservations').dataset.open = false

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })

    this.#setupDrag()
  }

  updateReservation(event) {
    const [_data, _status, xhr] = event.detail
    this.reservationsTarget.innerHTML = xhr.response

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector('#detailsModal')
    ).hide()
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.success')
    ).show()

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })
  }

  updateWaitlist(event) {
    const [_data, _status, xhr] = event.detail
    this.waitlistTarget.innerHTML = xhr.response

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector('#detailsModal')
    ).hide()
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.success')
    ).show()

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })
  }

  updateAll(event) {
    const [_data, _status, xhr] = event.detail
    this.allTarget.innerHTML = xhr.response

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector('#detailsModal')
    ).hide()
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.success')
    ).show()

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })
  }

  newReservation(event) {
    const [_data, _status, xhr] = event.detail
    this.reservationsTarget.innerHTML = xhr.response

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector('#reservationModal')
    ).hide()
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.success')
    ).show()

    document.getElementById('newReservationModal').reset()

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })

    this.#setupDrag()
  }

  newWaitingListEntry(event) {
    const [_data, _status, xhr] = event.detail
    this.waitlistTarget.innerHTML = xhr.response

    bootstrap.Modal.getOrCreateInstance(
      document.querySelector('#waitListModal')
    ).hide()
    bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.success')
    ).show()

    document.getElementById('newWaitingListModal').reset()

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Popover(tooltipTriggerEl)
    })

    this.#setupDrag()
  }

  newReservationError(event) {
    const [_data, _status, xhr] = event.detail
    document.querySelector('.newReservationError').innerHTML = xhr.response

    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.error')
    )
    document.querySelector('.error').querySelector('.toast-body').innerHTML =
      'An error has occurred.'
    toast.show()
  }

  newWaitlistError(event) {
    const [_data, _status, xhr] = event.detail
    document.querySelector('.newWaitlistError').innerHTML = xhr.response

    const toast = bootstrap.Toast.getOrCreateInstance(
      document.querySelector('.error')
    )
    document.querySelector('.error').querySelector('.toast-body').innerHTML =
      'An error has occurred.'
    toast.show()
  }

  #setupDrag() {
    const htmlCanvas = document.querySelector('#canvas')

    var rows = document.querySelectorAll('.reservation-rows .row')

    function handleDragStart(event) {
      htmlCanvas.dataset.currentObj = event.target.dataset.id
      htmlCanvas.dataset.objType = event.target.dataset.type
    }

    ;[].forEach.call(rows, function (row) {
      row.addEventListener('dragstart', handleDragStart, false)
    })
  }
}
