import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  updateRow(event) {
    const [_data, _status, xhr] = event.detail
    // Parse the response
    const responseHtml = xhr.response

    // Extract the row ID from the event or dataset
    const rowId = event.target.closest('tr').id

    // Locate the row in the DOM
    const rowElement = document.getElementById(rowId)

    if (rowElement) {
      // Replace the row's HTML with the updated HTML
      rowElement.innerHTML = responseHtml

      $('#dataTable').dataTable().fnDestroy()
      $('#dataTable').DataTable({
        paging: false,
        info: false,
        stateSave: false
      })
    } else {
      console.error(`Row with ID ${rowId} not found.`)
    }

    this.reinstantiateTooltips()
  }

  updateFields(event) {
    const [data, status, xhr] = event.detail

    // Extract data from JSON response
    const restaurantId = data.restaurant_id
    const gbp = data.gbp
    const gbpm = data.gbpm

    const gbpRow = document.getElementById(`gbp-${restaurantId}`)
    const gbpmRow = document.getElementById(`gbpm-${restaurantId}`)

    gbpRow.innerHTML = gbp
    gbpmRow.innerHTML = gbpm
  }

  openModal(event) {
    const [_data, _status, xhr] = event.detail
    this.formTarget.innerHTML = xhr.response

    var editModal = bootstrap.Modal.getOrCreateInstance(
      document.getElementById('editModal')
    )
    editModal.show()
  }

  reinstantiateTooltips() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      var tooltip = new bootstrap.Tooltip(tooltipTriggerEl)
      tooltipTriggerEl.addEventListener('click', function () {
        tooltip.hide()
      })
    })
  }
}
