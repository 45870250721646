// controllers/nested_form_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'target']
  static values = {
    wrapperSelector: String
  }

  connect() {
    this.wrapperSelectorValue =
      this.wrapperSelectorValue || '.nested-form-wrapper'
  }

  add(event) {
    event.preventDefault()

    // Clone the template content
    const content = this.templateTarget.content.cloneNode(true)

    // Create a new unique ID to replace the placeholder
    const timestamp = new Date().getTime()

    // Replace all instances of NEW_RECORD with the timestamp
    this._updatePlaceholders(content, timestamp)

    // Add the new form to the target
    this.targetTarget.appendChild(content)
  }

  remove(event) {
    event.preventDefault()

    // Find the closest wrapper element
    const wrapper = event.target.closest(this.wrapperSelectorValue)

    // Check if this is a new record or an existing one
    if (wrapper.dataset.newRecord === 'true') {
      // If it's a new record, just remove the element
      wrapper.remove()
    } else {
      // If it's an existing record, set the _destroy field to '1'
      const destroyField = wrapper.querySelector("input[name$='[_destroy]']")
      if (destroyField) {
        destroyField.value = '1'
      }

      // Hide the wrapper
      wrapper.style.display = 'none'
    }
  }

  _updatePlaceholders(content, timestamp) {
    // Replace NEW_RECORD in all input names, ids, and for attributes
    content
      .querySelectorAll('input, select, textarea, label')
      .forEach((element) => {
        // Update name attribute
        if (element.name) {
          element.name = element.name.replace(/NEW_RECORD/g, timestamp)
        }

        // Update id attribute
        if (element.id) {
          element.id = element.id.replace(/NEW_RECORD/g, timestamp)
        }

        // Update for attribute on labels
        if (element.tagName === 'LABEL' && element.hasAttribute('for')) {
          element.setAttribute(
            'for',
            element.getAttribute('for').replace(/NEW_RECORD/g, timestamp)
          )
        }
      })
  }
}
